// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper {
    width: 100%;
}

.slide {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    outline: none;

    background-color: #fff;
}

.slider-asset-container {
    width: 100%;
    height: 100%;
}

.slider-asset-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    z-index: 1001;
}

.swiper-pagination-bullet {
    background: #daabff !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/VideoContainer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;IAEvB,YAAY;IACZ,aAAa;;IAEb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".swiper {\r\n    width: 100%;\r\n}\r\n\r\n.slide {\r\n    width: 100%;\r\n    height: 100%;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n\r\n    border: none;\r\n    outline: none;\r\n\r\n    background-color: #fff;\r\n}\r\n\r\n.slider-asset-container {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.slider-asset-container img {\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: contain;\r\n}\r\n\r\n.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {\r\n    z-index: 1001;\r\n}\r\n\r\n.swiper-pagination-bullet {\r\n    background: #daabff !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
