// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rangeBarContainer {
    margin: 0 20px;
    max-width: 500;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
}

.defaultRange {
    height: 5px;
    flex: 1 1;
    background-color: #fff;
    border-radius: 8px;
}

.currentRange {
    height: 5px;
    background-color: #d5a9ef;
    border-radius: 8px;
}

.range {
    color: #fff;
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 300;
}
`, "",{"version":3,"sources":["webpack://./src/components/RangeBar.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,cAAc;IACd,WAAW;;IAEX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,SAAO;IACP,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".rangeBarContainer {\r\n    margin: 0 20px;\r\n    max-width: 500;\r\n    width: 100%;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    flex-direction: row;\r\n    gap: 10px;\r\n}\r\n\r\n.defaultRange {\r\n    height: 5px;\r\n    flex: 1;\r\n    background-color: #fff;\r\n    border-radius: 8px;\r\n}\r\n\r\n.currentRange {\r\n    height: 5px;\r\n    background-color: #d5a9ef;\r\n    border-radius: 8px;\r\n}\r\n\r\n.range {\r\n    color: #fff;\r\n    font-size: 12px;\r\n    font-family: 'Poppins';\r\n    font-weight: 300;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
