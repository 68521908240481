// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textInputContainer {
    margin-top: 10px;
    max-width: 500px;
    width: 100%;
    align-items: stretch;
}

.label {
    margin-bottom: 3px;
    width: 100%;
    text-align: left;
    font-family: 'Poppins';
    font-weight: 300;
    
    font-size: 14px;
    color: #fff;
}

.input {
    padding: 12px 10px;
    width: 100%;
    max-width: 500px;
    
    border-radius: 10px;
    border-color: #fff;
    background-color: #ffffff20;
    color: #fff;
}

.normal {
    margin-top: 3px;
    margin-bottom: 5px;
    width: 100%;
    
    text-align: left;
    font-family: 'Poppins';
    font-weight: 200;
    
    font-size: 12px;
    color: #fff;
}

.error {
    margin-top: 3px;
    margin-bottom: 5px;
    width: 100%;
    text-align: left;
    font-family: 'Poppins';
    font-weight: 300;
    
    font-size: 12px;
    color: #be0000;
}`, "",{"version":3,"sources":["webpack://./src/components/InputContainer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,sBAAsB;IACtB,gBAAgB;;IAEhB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;;IAEhB,mBAAmB;IACnB,kBAAkB;IAClB,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,WAAW;;IAEX,gBAAgB;IAChB,sBAAsB;IACtB,gBAAgB;;IAEhB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,sBAAsB;IACtB,gBAAgB;;IAEhB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".textInputContainer {\r\n    margin-top: 10px;\r\n    max-width: 500px;\r\n    width: 100%;\r\n    align-items: stretch;\r\n}\r\n\r\n.label {\r\n    margin-bottom: 3px;\r\n    width: 100%;\r\n    text-align: left;\r\n    font-family: 'Poppins';\r\n    font-weight: 300;\r\n    \r\n    font-size: 14px;\r\n    color: #fff;\r\n}\r\n\r\n.input {\r\n    padding: 12px 10px;\r\n    width: 100%;\r\n    max-width: 500px;\r\n    \r\n    border-radius: 10px;\r\n    border-color: #fff;\r\n    background-color: #ffffff20;\r\n    color: #fff;\r\n}\r\n\r\n.normal {\r\n    margin-top: 3px;\r\n    margin-bottom: 5px;\r\n    width: 100%;\r\n    \r\n    text-align: left;\r\n    font-family: 'Poppins';\r\n    font-weight: 200;\r\n    \r\n    font-size: 12px;\r\n    color: #fff;\r\n}\r\n\r\n.error {\r\n    margin-top: 3px;\r\n    margin-bottom: 5px;\r\n    width: 100%;\r\n    text-align: left;\r\n    font-family: 'Poppins';\r\n    font-weight: 300;\r\n    \r\n    font-size: 12px;\r\n    color: #be0000;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
