// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-assets-container {
    padding: 20px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;

    border: 1px dashed #000;
    border-radius: 10px;
}

.review-assets-container .review-img {
    position: relative;
    padding: 10px;
    width: 80px;
    height: 80px;

    border: 1px dashed #000;
    border-radius: 8px;
}

.review-assets-container .review-img span {
    position: absolute;
    top: -0px;
    right: -15px;
    transform: translate(-50%, -50%);

    width: 15px;
    height: 15px;


    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    background-color: #d83b3b;
    color: #fff;
    cursor: pointer;
}

.review-assets-container .review-img img, 
.review-assets-container .review-img video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/pages/LeaveReview/LeaveReview.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;;IAEX,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,eAAe;IACf,SAAS;;IAET,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,YAAY;;IAEZ,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,gCAAgC;;IAEhC,WAAW;IACX,YAAY;;;IAGZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;;IAElB,yBAAyB;IACzB,WAAW;IACX,eAAe;AACnB;;AAEA;;IAEI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".review-assets-container {\r\n    padding: 20px;\r\n    width: 100%;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: flex-start;\r\n    flex-wrap: wrap;\r\n    gap: 10px;\r\n\r\n    border: 1px dashed #000;\r\n    border-radius: 10px;\r\n}\r\n\r\n.review-assets-container .review-img {\r\n    position: relative;\r\n    padding: 10px;\r\n    width: 80px;\r\n    height: 80px;\r\n\r\n    border: 1px dashed #000;\r\n    border-radius: 8px;\r\n}\r\n\r\n.review-assets-container .review-img span {\r\n    position: absolute;\r\n    top: -0px;\r\n    right: -15px;\r\n    transform: translate(-50%, -50%);\r\n\r\n    width: 15px;\r\n    height: 15px;\r\n\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    border-radius: 50%;\r\n\r\n    background-color: #d83b3b;\r\n    color: #fff;\r\n    cursor: pointer;\r\n}\r\n\r\n.review-assets-container .review-img img, \r\n.review-assets-container .review-img video {\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: cover;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
