// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/imgs/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-profile-img-wrapper {
    width: 100vw;
    min-height: 100vh;

    display: flex;
    flex-direction: column;

    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
}

.upload-profile-img-container {
    margin: auto;
    position: relative;
    width: 100vw;
    min-height: 100vh;
    max-width: 500px;

    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ProfileContainer {
    margin-top: 20px;
    width: 100%;
    max-width: 500px;
    padding: 20px 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;

    background-color: #ffffff20;
    border-width: 1px solid #ffffff40;
    border-radius: 10px;

    cursor: pointer;
}

.ProfileImg {
    width: 50;
    height: 50;
    object-fit: contain;
}

.ProfileText {
    color: #fff;
    font-family: 'Poppins';
    font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/pages/UploadProfileImage/UploadProfileImage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;;IAEjB,aAAa;IACb,sBAAsB;;IAEtB,mDAAmD;IACnD,sBAAsB;IACtB,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;;IAEhB,aAAa;;IAEb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;;IAElB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,QAAQ;;IAER,2BAA2B;IAC3B,iCAAiC;IACjC,mBAAmB;;IAEnB,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".upload-profile-img-wrapper {\r\n    width: 100vw;\r\n    min-height: 100vh;\r\n\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n    background: url('../../assets/imgs/background.png');\r\n    background-size: cover;\r\n    background-repeat: no-repeat;\r\n    background-position-y: center;\r\n}\r\n\r\n.upload-profile-img-container {\r\n    margin: auto;\r\n    position: relative;\r\n    width: 100vw;\r\n    min-height: 100vh;\r\n    max-width: 500px;\r\n\r\n    padding: 20px;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n}\r\n\r\n.ProfileContainer {\r\n    margin-top: 20px;\r\n    width: 100%;\r\n    max-width: 500px;\r\n    padding: 20px 10px;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n    gap: 5px;\r\n\r\n    background-color: #ffffff20;\r\n    border-width: 1px solid #ffffff40;\r\n    border-radius: 10px;\r\n\r\n    cursor: pointer;\r\n}\r\n\r\n.ProfileImg {\r\n    width: 50;\r\n    height: 50;\r\n    object-fit: contain;\r\n}\r\n\r\n.ProfileText {\r\n    color: #fff;\r\n    font-family: 'Poppins';\r\n    font-weight: 300;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
